import isEmpty from 'lodash.isempty'
import React from 'react'
import { Order } from '~/bb-api/schemata'
import FeedbackForm from '~/components/FeedbackForm/FeedbackForm'
import { FeedbackFormContainer } from '~/components/FeedbackForm/FeedbackForm.ui'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import RecipesEmptyState from '~/components/RecipesEmptyState/RecipesEmptyState'
import WhatsForDinnerHeader from './WhatsForDinnerHeader/WhatsForDinnerHeader'
import { WhatsForDinnerProductListUI } from './WhatsForDinnerProductList/WhatsForDinnerProductList.ui'
import { WhatsForDinnerProps } from './WhatsForDinnerProductList/WhatsForDinnerProductList.types'
import { rem } from '@butcherbox/freezer'
import { Box } from '@chakra-ui/core'
import { consolidateOrderItems } from '~/utils/consolidateOrderItems'
import { getFilterItemSkus } from '~/routes/AccountBrowseRecipes/useCutsFromNextBoxGroup'
import * as Styles from '~/routes/AccountSavedRecipes/SavedRecipesList/SavedRecipesList.css'

export const getUrlForSkus = (skus: number[]) => {
  return `/account/recipes?category=all&sku=` + skus.join('&sku=')
}

const WhatsForDinner: React.FC<WhatsForDinnerProps> = ({ orders }) => {
  const recurringOrders = orders.filter((order) => order.type !== 'one_time')
  const finalOrder: Order = React.useMemo(() => {
    if (recurringOrders.length > 1 && isEmpty(recurringOrders[0].box.items)) {
      return recurringOrders[1]
    }
    return recurringOrders[0]
  }, [recurringOrders])

  const boxItems = React.useMemo(() => consolidateOrderItems(finalOrder), [
    finalOrder,
  ])

  const skus = React.useMemo(
    () => getFilterItemSkus(boxItems.map((i) => i.sku)),
    [boxItems]
  )

  const emptyStateMessage = `Cut information is currently unavailable for your box.
  Once your box has processed, you can explore recipes & tips for your ButcherBox cuts here.
  In the meantime, browse our full catalog of recipes & tips from Just Cook.`

  if (isEmpty(finalOrder.box.items))
    return (
      <>
        <WhatsForDinnerHeader />
        <RecipesEmptyState
          buttonText={`browse recipes & tips`}
          message={emptyStateMessage}
          title={`Your box is processing...`}
          url={`/account/recipes`}
        />
      </>
    )

  return (
    <>
      <WhatsForDinnerHeader
        shipmentDate={finalOrder?.trackingInformation.shipmentDate}
        shipmentStatus={finalOrder?.trackingInformation.status}
        skus={skus}
      />
      <Box backgroundColor="white" mt={{ tablet: rem(-78) }} pt={rem(10)}>
        <WhatsForDinnerProductListUI
          boxItems={boxItems}
          data-where="whats-for-dinner"
        />
      </Box>

      <PanelBreak
        className={Styles.PanelBreakAboveFeedbackSection}
        intensity="light"
      />

      <FeedbackFormContainer>
        <FeedbackForm formId="whats-for-dinner-feedback-form" />
      </FeedbackFormContainer>
    </>
  )
}

export default WhatsForDinner
