import { Box, ButtonLink, IconChefHat, Lockup, Text } from '@butcherbox/freezer'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import * as Styles from './RecipesEmptyState.css'

interface RecipesEmptyStateProps {
  message: string
  title: string
  url: string
  buttonText: string
  containerStyleProps?: any
}
const RecipesEmptyState = ({
  message,
  title,
  url,
  buttonText,
  containerStyleProps,
}: RecipesEmptyStateProps) => {
  return (
    <Box className={Styles.ContainerWrapper}>
      <Box className={Styles.ContainerClass} style={containerStyleProps}>
        <Lockup>
          <IconChefHat display="inlineBlock" variant="duo" />
          <Text data-cy={TEST_ID.RECIPES_EMPTY_STATE} variant="H2Bold">
            {title}
          </Text>
          <Text variant="H3Regular">{message}</Text>
          <ButtonLink
            href={url}
            size="standard"
            target={url.includes('justcook') ? '_blank' : '_self'}
            type="button"
            variant="primary"
          >
            {buttonText}
          </ButtonLink>
        </Lockup>
      </Box>
    </Box>
  )
}
export default RecipesEmptyState
