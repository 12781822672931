const Z_ORDER = {
  4: 6,
  6: 4,
  10: 12,
  12: 10,
}

export const zOrder = (order: number) => {
  for (const [currentOrder, newOrder] of Object.entries(Z_ORDER)) {
    if (order.toString() === currentOrder) {
      order = newOrder
      return order
    }
  }
  return order
}
