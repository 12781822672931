import HeroHeader from 'design/components/HeroHeader/HeroHeader'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { formatDate } from '~/utils'
import { WhatsForDinnerHeaderProps } from './WhatsForDinnerHeader.types'

const WhatsForDinnerHeader: React.FC<WhatsForDinnerHeaderProps> = ({
  shipmentDate = null,
  shipmentStatus = null,
}) => {
  const heroImage = useStaticQuery(graphql`
    query {
      bg: contentfulImageSet(uniqueId: { eq: "whats_for_dinner_hero" }) {
        ...ImageSet
      }
    }
  `)

  const getReadyText = React.useMemo(() => {
    if (shipmentStatus === 'processing') {
      return 'Get ready: Your box is processing and will ship soon!'
    }
    return shipmentDate
      ? `Box shipped on ${formatDate(shipmentDate, 'MMM D, YYYY')}`
      : 'Get Ready!'
  }, [shipmentDate, shipmentStatus])

  return (
    <HeroHeader
      bodyText="Explore recipes &amp; tips for your ButcherBox cuts."
      desktopOverlap={68}
      eyebrow={getReadyText}
      heading="Prepare for delicious meals!"
      imageSet={heroImage.bg}
    />
  )
}

export default WhatsForDinnerHeader
