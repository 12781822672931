import { Card, CardBody, CardMedia, Lockup, Text } from '@butcherbox/freezer'
import { Box, Grid, VisuallyHidden } from '@chakra-ui/core'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import { WhatsForDinnerProductListUIProps } from './WhatsForDinnerProductList.types'
import { zOrder } from '~/utils/zOrder'
import * as Styles from './WhatsForDinnerProductList.css'
import { getTagInfoForSku } from '~/data/justcook-tags-data'
import { Flag } from 'design/components/ModalCardRadio/ModalCardRadio'
import { Link } from 'gatsby'
import { getValidSkuForActualSku } from '~/routes/AccountBrowseRecipes/proteinAndCutsData'

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children

export const WhatsForDinnerProductListUI: React.FC<WhatsForDinnerProductListUIProps> = ({
  boxItems,
  ...props
}) => (
  <Grid as="div" className={Styles.Grid} {...props}>
    {boxItems.map((_item, i) => {
      // most functions want the sku to be a number
      const item = { ..._item, sku: Number(_item.sku) }

      const tagInfo = getTagInfoForSku(item.sku)
      const cutHasRecipes = tagInfo && !tagInfo.hasNoRecipes

      const tagId = getValidSkuForActualSku(item.sku)
      const url = `/account/recipes?sku=${tagId}`

      const { BottomRow, Selectable, CardBox } = Styles
      return (
        <Box
          className={`${CardBox} ${cutHasRecipes ? Selectable : ''}`}
          data-cy={TEST_ID.WHATS_FOR_DINNER_PRODUCT}
          data-cy-cuts-rank={zOrder(i + 1)}
          data-cy-sku={item.sku}
          key={item.sku}
          order={{ tablet: zOrder(i + 1) }}
        >
          <ConditionalWrap
            condition={cutHasRecipes}
            wrap={(children) => <Link to={url}>{children}</Link>}
          >
            <Card
              boxShadow="default"
              className={Styles.Card}
              minHeight={302}
              width="fluid"
            >
              <CardMedia alt={item.description} height={218} src={item.image} />

              {!cutHasRecipes && <Flag zIndex={1}>Recipes Coming!</Flag>}

              <CardBody className={Styles.CardBody} variant="compact">
                <Box className={BottomRow}>
                  <Box>
                    <Text component="h3" variant="H3Bold">
                      {item.description}
                    </Text>
                    <Lockup>
                      <Text variant="Body1Regular">
                        <VisuallyHidden as="span">
                          Each unit of {item.description} contains&nbsp;
                        </VisuallyHidden>
                        {item.quantity > 1 && `(${item.quantity}) `}
                        {item.packSize}
                      </Text>

                      {cutHasRecipes && (
                        <Text color="spicedCrimson" variant="Eyebrow">
                          View Recipes & Tips
                        </Text>
                      )}
                    </Lockup>
                  </Box>
                </Box>
              </CardBody>
            </Card>
          </ConditionalWrap>
        </Box>
      )
    })}
  </Grid>
)
