import { Box } from '@chakra-ui/core'
import type { RouteComponentProps } from '@reach/router'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import React from 'react'
import useOrders from '~/hooks/useOrders'
import WhatsForDinner from './WhatsForDinner/WhatsForDinner'

const AccountWhatsForDinner: React.FC<RouteComponentProps> = () => {
  const { data: orders = [], status } = useOrders({ limit: 2 })

  return status === 'loading' ? (
    <LoadingSpinner />
  ) : status === 'error' ? (
    <Box margin="0 auto">
      Sorry, something went wrong retrieving your order.
    </Box>
  ) : (
    <WhatsForDinner orders={orders} />
  )
}

export default AccountWhatsForDinner
